<template>
  <v-card v-if="model.id" class="elevation-2" tile>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab>Caja Abierta</v-tab>
      <v-tab @click="ListaCajas">Listado Cajas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Caja Abierta -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="model.last_movement.estatus_id === 22" dense>
              <v-col cols="6" class="py-5">
                <v-toolbar color="green darken-1" dark>
                  <span class="font-weight-bold text-h4 pr-2">$ {{intlRound(totalIncome)}} </span>
                  <span class="font-weight-bold title">Ingresos</span>
                </v-toolbar>
                <DataTableMain :datatable="tableIngresos" :on-success="successAction" :items-action="tableAction" />
              </v-col>
              <v-col cols="6" class="py-5">
                <v-toolbar color="amber darken-2" dark>
                  <span class="font-weight-bold text-h4 pr-2">$ {{intlRound(totalEgress)}}</span>
                  <span class="font-weight-bold title">Egresos</span>
                </v-toolbar>
                <DataTableMain :datatable="tableEgresos" :on-success="successAction" :items-action="tableAction" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Cajas Abierta y Cerradas -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <DataTableMain :datatable="table" :on-success="successAction" :items-action="tableAction" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import { Permission } from '@/mixins/permissionMain'
import { NumberUtils } from "@/mixins/NumberMixin"
export default {
  name: 'CajasVentasDetails',
  mixins: [Permission, NumberUtils],
  components: {
    DataTableMain
  },
  props: ['model', 'onSuccess'],
  data: () => ({
    tab: 0,
    table: {
      header: {
        options: [
          { title: 'Nuevo', icon: 'mdi-plus', color: 'success', action: 'newDataArticulo', code: 'process-inv.execute' },
        ],
        titles: [
          { text: 'Empleado', value: 'nombrecompleto', class: 'black--text' },
          { text: 'Estatus', value: 'StatusMov', class: 'black--text', fieldMain: 'estatus'},
          { text: 'Fecha Abierto', value: 'Apertura', align: 'center', class: 'black--text' },
          { text: 'Fecha Cierre', value: 'Cerrado', align: 'center', class: 'black--text' },

          { text: 'Monto Base', value: 'DinamicImporte', class: 'black--text', fieldMain: 'MontoBase'},          
          { text: 'Importe Ventas', value: 'DinamicImporte', class: 'black--text', fieldMain: 'Importe'},
          { text: 'Importe Depsitos', value: 'DinamicImporte', class: 'black--text', fieldMain: 'Depositos'},
          { text: 'Importe Egresos', value: 'DinamicImporte', class: 'black--text', fieldMain: 'Retiros'},
          { text: 'Importe', value: 'DinamicImporte', class: 'black--text', fieldMain: 'Total' },
          { text: '', value: 'ActionsGral', class: 'black--text'}
        ],
        loading: false,
        showSearch: true
      },
      body: {
        data: [],
        actions: [
          { title: 'Ver Detalles', icon: 'mdi-eye', color: 'light-blue darken-2', action: 'viewDetailCaja', code: 'libre', hidden: '' }
        ]
      },
      footer: {
        hideFooter: true
      }
    },
    cajasOpen: [],
    totalIncome:0.0,
    tableIngresos: {
      header: {
        options: [],
        titles: [
          { text: 'Folio Venta', value: 'folio', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          { text: 'Descripcion', value: 'description', class: 'black--text' },
          { text: 'Forma Pago', value: 'way_to_pay', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' }
          { text: "Monto", value: "DinamicImporte", class: "black--text", fieldMain: 'amount' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    },
    totalEgress:0.0,
    tableEgresos: {
      modelMain: [],
      header: {
        options: [],
        titles: [
          { text: 'Descripción', value: 'description', class: 'black--text' },
          { text: 'Fecha', value: 'date', class: 'black--text' },
          // { text: 'Monto', value: 'amount', class: 'black--text' },
          { text: "Monto", value: "DinamicImporte", class: "black--text", fieldMain: 'amount' }
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [],
      },
      footer: {
        paging: true,
      },
    }
  }),
  mounted () {
    // this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETListObjectsTwoService']),
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\
    successAction (item) {
      item.item = this.model
      this.onSuccess(item)
    },
    tableAction (item) {
      this.onSuccess(item)
    },
    DetallesCajas(detalles) {
      const income = detalles.filter( (item) => item.descripcion.descripcion && item.descripcion.status_id === 24 )
      const egress = detalles.filter( (item) => item.descripcion && item.descripcion.status_id === 25 )
      this.totalIncome = 0
        this.ingresosMain = income.map(item=>{
          this.totalIncome += (item.descripcion.pago)? item.descripcion.pago.importe : item.descripcion.monto
          return  {
                    id: item.id,
                    date: item.descripcion.fecha,
                    folio: item.descripcion.folio,
                    amount: (item.descripcion.pago)? item.descripcion.pago.importe : item.descripcion.monto,
                    user: item.descripcion.usuario.usuario,
                    description: item.descripcion.descripcion,
                    way_to_pay_id: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.id : item.descripcion.pago.forma_pago.id,
                    way_to_pay: (item.descripcion.forma_pago_id) ? item.descripcion.forma_pago.nombre : item.descripcion.pago.forma_pago.nombre
                  }
        });
        this.tableIngresos.body.data = []
        this.tableIngresos.body.data.push(...this.ingresosMain)

        this.totalEgress = 0
        this.egresosMain = egress.map(item=>{
          this.totalEgress += item.descripcion.monto
          return  {
                    id: item.id,
                    amount: item.descripcion.monto,
                    description: item.descripcion.descripcion,
                    date: item.descripcion.fecha,
                    user: item.descripcion.usuario.usuario
                  }
        });
        this.tableEgresos.body.data = []
        this.tableEgresos.body.data.push(...this.egresosMain)
    },
    ListaCajas () {
      this.table.body.data = []
      this.table.header.loading = true
      const router = this.get_urls['GET/venta/cajas/view-cajas']
      this.GETListObjectsTwoService({ url: router, addicional: `?folio=${this.model.folio}` });
    }
  },
  computed: {
    ...mapGetters(['get_urls', 'get_objectsTwo'])
  },
  watch: {
    model (row) {
      if (row.last_movement) {
        if(row.last_movement.detalles)this.DetallesCajas(row.last_movement.detalles)
      }
    },
    get_objectsTwo (data) {
      this.table.body.data = data
      this.table.header.loading = false;
    }
  }
}
</script>
  
  <style>  
  </style>
    