<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card tile>
          <v-card-text>
            <v-row dense class="pb-3">
              <v-col cols="12">
                <v-btn v-if="showMainPermiss('venta.clientes.create-update')"
                class="mx-2" color="info" @click="successtableAction({ action: 'newClientData', item: {} })" tile dark>
                  <v-icon left>mdi-plus</v-icon> Agregar Cliente
                </v-btn>
              </v-col>
              <v-divider width="5" class="pa-3 black--text" />
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4" md="3" lg="3">
                <SelectedDinamicField :setter-model="sucursal_id" :item-action="SelectedSucursalField" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateInit" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <DatePicker :item-action="fieldDateFinal" :on-success="actionFieldData" />
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-btn color="success" @click="searchSales" dark>
                  <v-icon left dark>mdi-calendar-search</v-icon> Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <DataTableMain :datatable="table" :on-success="successtableAction" :items-action="successtableAction" />
      </v-col>
      <v-col cols="12">
        <ViewDetailsMain class="my-2" :object="objectDetail" type="VentasDetails" :items-action="successtableAction" />
      </v-col>
    </v-row>
    <MainFormDialog v-if="get_dialogMain.dialog" :model="object" :params="paramsDialog"
      :on-success="ResponseForm"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMain from '@/components/tables/DataTableMain'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import DatePicker from '@/components/fields/DatePicker'
import ViewDetailsMain from '@/components/details/ViewDetailsMain.view'
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import { UserSession } from '@/store/modules/config'
import { DatesUtils } from '@/mixins/datesUtilsMixin'
import { Permission } from '@/mixins/permissionMain'
export default {
  mixins: [DatesUtils, Permission],
  name: 'VentaView',
  components: {
    DataTableMain,
    MainFormDialog,
    DatePicker,
    ViewDetailsMain,
    SelectedDinamicField
  },
  data: () => ({
    table: {
      modelMain: [],
      showSelect: true,
      singleSelect: true,
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: ''},
          {
            title: 'Reportes',
            icon: 'mdi-cloud-download',
            color: 'light-blue lighten-1',
            code: 'venta.reporte',
            options: [
              { title: 'Reporte de Facturado / No Facturado', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportBills', code: 'venta.reporte.facturados-nofacturados-excel' },
              { title: 'Reporte de Ventas General', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportSaleGral', code: 'venta.reporte.ventas-generales-excel' },
            ]
          }
        ],
        titles: [
          { text: 'Folio', value: 'caracteristicas.folio_venta', class: 'black--text' },
          { text: 'Cliente', value: 'cliente.NombreCompleto', class: 'black--text' },
          { text: 'Caja', value: 'caja.nombre', class: 'black--text' },
          { text: 'Sucursal', value: 'caracteristicas.sucursal.nombre', class: 'black--text' },
          // { text: 'Almacen', value: 'caracteristicas.almacen.nombre', class: 'black--text' },
          { text: '# Pagos', value: 'numero_pagos', class: 'black--text' },
          { text: 'Abonos', value: 'en_abonos', class: 'black--text' },
          { text: "SubTotal", value: "DinamicImporte", class: "black--text", fieldMain: 'subtotal' },
          { text: "Impuestos", value: "DinamicImporte", class: "black--text", fieldMain: 'impuesto' },
          { text: "Total", value: "DinamicImporte", class: "black--text", fieldMain: 'total' },

          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text' },
        ],
        loading: false,
      },
      body: {
        data: [],
        actions: [
          { title: 'Imprimir Ticket', icon: 'mdi-file-pdf-box', color: 'blue darken-3', action: 'ViewDownloadData', code: 'venta.recibos-pdf' },
          { title: 'Imprimir Ticket Factura', icon: 'mdi-file-pdf-box', color: 'blue darken-3', action: 'ViewDownloadFacturaData', code: 'venta.recibos-factura-pdf' }
        ],
      },
      footer: {
        paging: true,
      },
    },
    SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales', parmas: '', cols: 3 },
    fieldDateInit: { field: 'textDatePicker', name: 'Fecha Inicio', nameid: 'fecha_init', default: true, cols: 3 },
    fieldDateFinal: { field: 'textDatePicker', name: 'Fecha Final', nameid: 'fecha_final', default: true, cols: 3 },
    paramsDialog: {},
    object: {},
    params: {},
    showSucursal: false,
    sucursal_id: 0,
    titleDialog: ''
  }),
  mounted() {
    this.sucursal_id = UserSession().sucursal_id
    this.params.sucursal_id = this.sucursal_id
    const date = new Date()
    const fecha = this.formatoFecha(date, 'yyyy-mm-dd')
    this.params.fecha_init = fecha
    this.params.fecha_final = fecha
    this.ExecuteUrl('POST/venta');
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'POSTListObjectsService', 'GETExportPdftSerice', 'GETObjectPdftService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    // *==========*==========*==========*==========* \\
    actionFieldData(obj) {
      this.params[obj.id] = (obj.data)? obj.data : null;
    },
    successtableAction(item) {
      this[item.action](item)
    },
    refreshData(item) {
      this.table.header.loading = true;
      this.ExecuteUrl('POST/venta');
    },
    toPending(item) {
      this.object = item;
      const data = { en_abonos: false, importe_pagar: item.item.total }
      this.paramsDialog = {
        url: this.get_urls['POST/venta/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: data
      };
      this.SHOW_DIALOG_MAIN({ type: 'create', form: 'process', with: 650, title: 'TERMINAR VENTA' });
    },
    toCharge(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create',
        form: 'process',
        with: 650,
        title: 'REALIZAR PAGO',
      });
    },
    toPay(item) {
      this.object = item;
      this.paramsDialog = {
        url: this.get_urls['POST/venta/execute'],
        message: 'Registro Agregado Correctamente',
      };
      this.SHOW_DIALOG_MAIN({
        type: 'create',
        form: 'process',
        with: 650,
        title: 'REALIZAR PAGO',
      });
    },
    toBill(item) {
      this.object = item
      let data = {}
      if(item.item.cliente_id !== 1) {
        data = { cliente_id: item.item.cliente_id, cliente: item.item.cliente }
      }
      this.paramsDialog = {
        url: this.get_urls['POST/venta/execute'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        data: data
      };
      this.SHOW_DIALOG_MAIN({ type: 'sale-bill', form: 'process', with: 650, title: 'FACTURAR VENTA' });
    },
    toCancel(item) {
      this.object = item
      this.paramsDialog = {
        url: this.get_urls['POST/venta/execute'],
        message: 'Registro Agregado Correctamente'
      }
      this.SHOW_DIALOG_MAIN({ type: 'cancelSale', form: 'process', with: 650, title: 'CANCELAR VENTA' })
    },
    ViewDownloadData({ item }) {
      this.titleDialog = 'Ticket Venta'
      const route = this.get_urls['GET/venta/ventas/recibos/pdf/{folio}'];
      this.GETObjectPdftService({url: route, params: item.folio, replace: '{folio}' })
    },
    ViewDownloadFacturaData({ item }) {
      this.titleDialog = 'Ticket Factura'
      const route = this.get_urls['GET/venta/ventas/recibos-factura/pdf/{folio}'];
      this.GETObjectPdftService({url: route, params: item.folio, replace: '{folio}' })
    },
    // *==========*==========*==========*==========* \\
    newClientData(item) {
      this.object = { enviar_factura: true }
      this.paramsDialog = {
        form: this.get_Clientesform,
        url: this.get_urls['POST/venta/clientes'],
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'create-client', with: 850, title: 'Nuevo Cliente' })
    },
    ExportBills(item) {
      this.object = { tipo_reporte: 'excel', sucursal_id: this.params.sucursal_id, nombre: 'Articulos'}
      this.paramsDialog = {
        form: this.get_SearchVentasForm,
        url: this.get_urls['POST/venta/reportes/facturados-nofacturados'],
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true,
        // returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reportebills', with: 900, title: 'REPORTE DE ARTICULOS' })
    },
    ExportSaleGral(item) {
      this.object = { tipo_reporte: 'excel', sucursal_id: this.params.sucursal_id, nombre: 'VentasGenerales' }
      this.paramsDialog = {
        form: this.get_RangeDateTimeForm,
        url: this.get_urls['POST/venta/reportes/ventas-generales'],
        message: 'Registros no Encontrados',
        exportData: true,
        setmodel: true,
        // returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'reportesalegral', with: 900, title: 'REPORTE GENERAL VENTAS' })
    },
    ResponseForm ({ name, model }) {
      if (name === 'create-client') {
        if (model.message) {
          this.$swal({
            type: 'error',
            icon: 'error',
            title: 'Oops...!',
            text: val.message
          })
          return
        }

        this.$swal({
          type: 'success',
          icon: 'success',
          title: 'Existoso!!',
          text: 'Registro Agregado Correctamente',
          timer: 2000
        })
      }
    },
    // *==========*==========*==========*==========* \\
    searchSales () {
      this.refreshData({})
      // this.ExecuteUrl('POST/venta');
    },
    ExecuteUrl(url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url];
        this.POSTListObjectsService({ url: router, data: this.params });
      } else {
        this.GETUrlServices();
      }
    },
  },
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects', 'get_object', 'get_objectPdf',
      'get_Clientesform', 'get_SearchVentasForm', 'get_RangeDateTimeForm', 'get_ShowPdfticketDialog']),
    objectDetail() {
      if (this.table.modelMain.length > 0) {
        return this.table.modelMain[0];
      }
      return {};
    },
  },
  watch: {
    get_urls(val) {
      this.ExecuteUrl('POST/venta');
    },
    get_objects(rows) {
      if (rows) {
        this.table.modelMain = [];
        if (rows.length > 0) {
          this.table.modelMain.push(rows[0]);
        }
        this.table.body.data = rows;
        this.table.header.loading = false;
      }
    },
    get_objectPdf(val) {
      this.object = val
      this.paramsDialog = {
        form: this.get_ShowPdfticketDialog,
        message: 'Registro Agregado Correctamente',
        setmodel: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'view-pdf', with: 850, title: this.titleDialog, body:{ isbutton: true } })
    },
  },
};
</script>

<style></style>
