<template>
  <v-card :loading="!showIframePdf" tile elevation="0">
    <v-card-text>
      <iframe v-if="showIframePdf"
        class="iframe-custom"
        :src=this.pdfPage
        frameborder="0"
        scrolling="no"
        allowfullscreen>
      </iframe>
    </v-card-text>
  </v-card>

</template>

<script>
import axios from 'axios'
import Vue from 'vue'
// import { HeaderFileGral2 } from '@/store/modules/config'
export default {
  props: ['itemAction', 'setterModel', 'onSuccess'],
  data: () => ({
    ruta: '',
    objet: null,
    pdfPage: null,
    showIframePdf: false
  }),
  components: {},
  created () {
    if (this.setterModel) {
      // console.log(this.itemAction)
      // console.log(this.setterModel)
      this.objet = this.setterModel
      this.getDinamicData(this.itemAction.route)
    }
  },
  methods: {
    getDinamicData (url) {
      const token = Vue.$cookies.get('token_pv')
      const header = { Authorization: `Bearer ${token}` }
      axios.post(url, this.objet, { headers: header }).then((response) => {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(response.data.pdf.replace(/\s/g, ''))
        var len = binary.length
        var buffer = new ArrayBuffer(len)
        var view = new Uint8Array(buffer)
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        const blob = new Blob([view], { type: 'application/pdf' })
        this.pdfPage = URL.createObjectURL(blob)
        this.showIframePdf = true
      }).catch((error) => {
        if (error == null) console.log('Error')
      }).finally(() => {
      })
    }
  },
  watch: {
    objet (val){
      console.log(val)
    }
  }
}
</script>
<style scoped>
.iframe-custom {
  width: 100%;
  height: 50vh;
}
</style>
