<template>
  <div style="width: 100%">
    <!-- <pdf v-if="showPDF" :src="src"></pdf> -->
    <iframe v-if="showPDF"
        class="iframe-custom"
        :src=src
        frameborder="0"
        scrolling="no"
        allowfullscreen>
      </iframe>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import { HeaderGral } from '@/store/modules/config';
  import pdf from 'vue-pdf'
  export default {
    props: ['itemAction', 'setterModel', 'onSuccess'],
    data: () => ({
      item: {},
      loading: false,
      showPDF: false,
      src: ''
    }),
    created() {
      this.laadData()
    },
    methods: {
      laadData () {
        if (this.itemAction.url) {
          if (this.itemAction.post) {
            this.postDinamicData(this.itemAction.url);
            return
          }
          this.getDinamicData(this.itemAction.url);
        }else {
          pdf.created
          this.src = `data:application/pdf;base64,${this.setterModel}`
          this.showPDF = true
        }
      },
      getDinamicData (url) {
      this.showPDF = false
      const header = HeaderGral()
      const p = (this.itemAction.params) ? this.itemAction.params : ''
      axios.get(url+p, header).then((response) => {
        this.item = response.data
        this.src = `data:application/pdf;base64,${this.item.pdf}`
        this.showPDF = true
        this.loading = false
      }).catch((error) => {
        if (error == null) {
          console.log('Error');
        }
      });
      },
      postDinamicData(url) {
      const header = HeaderGral();
      axios.post(url, {}, header).then((response) => {
        this.items = []
        this.items.push(...response.data)
        this.loading = false
      }).catch((error) => {
        if (error == null) {
          console.log('Error');
        }
      });
      },
    },
    watch: {},
    mounted () {},
    components: {
      pdf
    }
  }
  </script>
  
  <style scoped>
  .iframe-custom {
    width: 100%;
    height: 50vh;
  }
  </style>
  